import React, { useRef, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import BloomDomainViewer from './BloomDomainViewer';
import './CourseView.css';
import { useSettings } from '../context/SettingContext';
import { FaBullseye, FaLightbulb, FaChalkboardTeacher } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
export default function CourseView(props) {
  const { getSettingByName } = useSettings();
  const courseRef = useRef();

  useEffect(() => {
    getSettingByName('domains');
  }, []);

  const handlePrint = useReactToPrint({
    content: () => courseRef.current,
    documentTitle: props.data.name || 'Course Report',
  });

  return (
    <div ref={courseRef}>
      <Row>
        <Col lg="12" md="12" sm="12" sx="12" className="courseInformation">
          Course Information<button title="Print" onClick={handlePrint} className="btn btn-primary printButton" style={{ marginTop: '20px' }}><FaPrint /></button>
        </Col>
      </Row>

      {(props.data.name !== undefined) ? (
        <div className="viewContainer" >
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Course Name</Col>
            <Col className="viewValue">{props.data.name}</Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Course Description</Col>
            <Col className="viewValue">{props.data.description}</Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Course Code</Col>
            <Col className="viewValue">{props.data.code}</Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Delivery Method</Col>
            <Col className="viewValue">{props.data.deliveryMethod}</Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" sx="12" className="viewLabel h2">
              <FaLightbulb /> Learning Goals
            </Col>
          </Row>
          {props.data.goals.map((goal, index) => (
            <Row key={"g" + index}>
              <Col lg="12" md="12" sm="12" sx="12">
                - {goal}
              </Col>
            </Row>
          ))}
          <Row>
            <Col lg="12" md="12" sm="12" sx="12" className="viewLabel h2">
              <FaBullseye /> Learning Objectives
            </Col>
          </Row>
          {props.data.learningObjectives.map((learningObjective, index1) => (
            <div className="learningObjectiveBlock" key={"lo" + index1}>
              <Row>
                <Col className="learningObjectiveText">
                  {learningObjective.text}
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col lg="8" md="6" sm="7" sx="12">
                  <Row>
                    <div className="viewLabel h3">
                      <FaChalkboardTeacher /> Related Activities
                    </div>
                    {learningObjective.activities.map((activity, index2) => (
                      <Col
                        lg="12"
                        md="12"
                        sm="12"
                        sx="12"
                        className="activityBlock"
                        key={"a" + index2}
                      >
                        <div className="activityName">{activity.name}</div>
                        <div>
                          <strong>Activity Type: </strong>
                          {activity.type}
                        </div>
                        <div>
                          <strong>Phase: </strong>
                          {activity.phase}
                        </div>
                        <div>
                          <strong>Description: </strong>
                          {activity.description}
                        </div>
                        <strong>Content: </strong>
                        <div className="activityContent">{activity.content}</div>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col lg="4" md="6" sm="5" sx="12" className="domainContainer">
                 
                  <BloomDomainViewer
                    domainName={learningObjective.domain}
                    levelName={learningObjective.level}
                    masteryLevel={learningObjective.masteryLevel}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Row>
            <Col>Please select a course</Col>
          </Row>
        </div>
      )}

     
    </div>
  );
}
