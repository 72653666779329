import React,{useState} from 'react'
import { Form, Row,Col } from 'react-bootstrap'
import  { useFramework } from '../context/FrameworkContext'
import RoleEditor from './RoleEditor'
import {FaRegMinusSquare,FaRegPlusSquare, FaUserCog} from 'react-icons/fa'
import FrameworkIcon from '../assets/frameworkIcon.svg'
import './FrameworkEditor.css' 


export default function FrameworkEditor() {
const [isExpanded,setIsExpanded]=useState(false)
const handleToggleExpand=()=>{
    setIsExpanded(!isExpanded);
}
const {framework,setFramework,addEmptyRole}=useFramework();
const handleAddEmptyRole=()=>{
    addEmptyRole();
}
const handleInputChange=(event)=>{
    let value=event.target.value;
    console.log(value)
    setFramework({...framework,[event.target.name]:value})

}


  return (
    <>
    <p></p>
    <div>
        <p>
        <img alt="framework icon" src={FrameworkIcon} width="20px"/> <b>Framework</b>
        </p>
    <Row>
        <Col lg="2" md="3" xs="4" sm="4" className="appEditorLabel frameworkEditorLabel ">
        
     
            <Form.Label >Name</Form.Label>
        </Col>
        <Col>
            <Form.Control   name="frameworkName"
                            type="text" 
                            defaultValue={framework.frameworkName}
                            onChange={handleInputChange} 
                           ></Form.Control>
        </Col>
    </Row>
    <Row>
        <Col lg="2" md="3" xs="4" sm="4" className="frameworkEditorLabel appEditorLabel">
        <Form.Label className="col-md-*" >Description</Form.Label>
        </Col>
        <Col>
        <Form.Control className="col-md-*"
                        name="frameworkDescription" 
                        type="text" 
                        defaultValue={framework.frameworkDescription}
                        onChange={handleInputChange}
                      ></Form.Control>
        </Col>
        
    </Row>    
            
       
      
    </div>
    <p></p>
    <div className="appTreeLines">
     {<><div className="expandCollapseButton" onClick={handleToggleExpand}>{isExpanded?<FaRegMinusSquare/>:<FaRegPlusSquare/>} <FaUserCog/><b> Roles ({framework.roles.length})</b></div> </>}
      <div className="appIdented" style={{display:isExpanded?'block':'none'}}>
        {framework.roles?framework.roles.map((role)=>{
            return(
            
            <RoleEditor 
            key={role._id}
            id={role._id}
            name={role.name}
            description={role.description}
            functions={role.functions}
            
            ></RoleEditor>
            
            
            )

        }):null}
        <button className="appEditorButtons roleEditorLabel"
         style={{left:'-30px',top:'20px'}}
         onClick={handleAddEmptyRole}
         
         >+ <FaUserCog/></button>
    </div>
    </div>
    <p> </p>
    </>
  )
}
