import React,{useEffect} from 'react'
import CertificateView from './CertificateView'
import GenericList from './GenericList'
import {useCertificate} from '../context/CertificateContext'
import{Row,Col} from 'react-bootstrap'
import './CertificatesList.css'
export default function CertificatesList() {
  const {getUserCertificates,setActionType,setEditModalIsVisible,removeCertificate,saveCertificate,userCertificates,selectedCertificateId,setSelectedCertificateId,message,setMessage,setSelectedCertificate,selectedCertificate}=useCertificate();
  
  useEffect(()=>{
    getUserCertificates();  
    setMessage({target:"Certificates",header:"",text:"",variant:"",buttons:[],isVisible:false});
  },[]);

  const handleSelect=async(event)=>{
    await setSelectedCertificateId(event.target.getAttribute("selectid"))
    let currentCertificate=await userCertificates.find(certificate=>certificate._id===event.target.getAttribute("selectid"))
    if (currentCertificate) {setSelectedCertificate(currentCertificate)}
  }
  const handleDelete=(event)=>{
    setSelectedCertificateId(event.target.getAttribute("deleteid"));
    setMessage({target:"Certificates",header:"Are you sure ?",text:"Are you sure you want to delete this certificate and all its related information?",variant:"warning",buttons:[{text:"Yes, I am sure",action:handleCompleteDelete},{text:"Cancel",action:handleCancel}],isVisible:true})

  }
  const handleCompleteDelete=()=>{
    removeCertificate(selectedCertificateId);
    setMessage({target:"Certificates",header:"",text:"",variant:"",buttons:[],isVisible:false});
  }
  const handleCancel=()=>{
    setMessage({target:"Certificates",header:"",text:"",variant:"",buttons:[],isVisible:false});
  }
  const handleEdit=async(event)=>{
    await setActionType("edit")
    await setSelectedCertificateId(event.target.getAttribute("editid"));
    let currentCertificate=await userCertificates.find(certificate=>certificate._id===event.target.getAttribute("editid"))
    if (currentCertificate) {await setSelectedCertificate(currentCertificate)}
    setEditModalIsVisible(true)
  }
  const handleAdd=async()=>{
    await saveCertificate()

    
  }

  return (
    <div className="certificateListContainer">
    <Row>
      <Col lg="4" md="4" sm="6" sx="12">
      <GenericList
        listHeader="Certificates"
        columnsHeaders={["Title","Acheivement Description","For"]}
        columnsNames={["title","acheivement","certificateFor"]}
        data={userCertificates}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleAdd={handleAdd}
        selectedItemId={selectedCertificateId}
        listMessage={message}
      />
      </Col>
      <Col lg="8" md="8" sm="6" sx="12">
      
      <CertificateView
        data={selectedCertificate}
      />
      </Col>
    </Row>

    </div>
  )
}




