import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import {FaBinoculars,FaProjectDiagram,FaBolt,FaHome,FaTags} from 'react-icons/fa'
import { LuBrainCircuit } from "react-icons/lu";
import Header from '../components/Header';

import './HomePage.css'
import homeImage1 from '../assets/homeImage1.png'
import homeImage2 from '../assets/homeImage2.png'
import homeImage3 from '../assets/homeImage3.png'
import homeImage4 from '../assets/homeImage4.png';
import IconBlock from '../components/IconBlock';
import PricingPlans from '../components/PricingPlans';
export default function HomePage() {
  return (
    <>
    <Container>
    <p className="appPageIcon"><FaHome/></p>
    <h2 className="appPageTitle"> | EDU Framework</h2>
    <div className="homeContainer">
      <p></p>
      <Row >
        <Col lg="7" md="7" sm="12" xs="12" className="textPart">
        <h2><span className="titleIcon"><FaBinoculars/></span> Informed Perspective to Professional Development Training Design</h2>
        <p>Introducing Edu Framework, an innovative software solution tailored for curriculum developers and instructional designers to efficiently develop and oversee competency frameworks and comprehensive curriculum mapping. This tool facilitates the establishment of a multi-tiered structure outlining roles, functions, competencies, and aligning them with programs, goals, objectives, and activities to optimize the connection between requirements and corresponding training.</p>
        </Col>
        <Col lg="5" md="5" sm="12" xs="12">
          <img src={homeImage1} style={{marginLeft:'80px'}} width="80%"/>
        </Col>
       
      </Row>
      <Row >
        <Col lg="7" md="7" sm="12" xs="12" className="textPart">
        <h2><span className="titleIcon"><LuBrainCircuit /></span> Powered by AI</h2>
        <p>
        Edu Framework harnesses the power of the latest AI generative technologies to revolutionize the creation of varied learning activities within your courses. By understanding and aligning with the learning goals and objectives you specify, the platform generates activities that are not only relevant but also engaging. It takes into account the delivery method, ensuring that the content is appropriately tailored for different instructional formats. Furthermore, it incorporates the Bloom taxonomy, addressing both the domain and the specific cognitive level the activity is designed to serve. This ensures a comprehensive and targeted approach to educational content creation, enhancing the learning experience and achieving optimal educational outcomes. 
        </p>
        </Col>
        <Col lg="5" md="5" sm="12" xs="12">
          <img src={homeImage4} width="80%"/>
        </Col>
       
      </Row>
      <Row >
        <Col lg="7" md="7" sm="12" xs="12" className="textPart">
        <h2><span className="titleIcon"><FaBolt/></span> Empowered with adult learning common theories and models</h2>
        <p>
          Edu Framework embed the knowledge of the commoly used adult learning and instructional design theories and models, starting with the framework structure to
          the usage of Bloom domains, learning objectives mapping and learning activites pattern and ROPES delivery model. 
        </p>
        </Col>
        <Col lg="5" md="5" sm="12" xs="12">
          <img src={homeImage2} width="80%"/>
        </Col>
       
      </Row>
      <Row >
        <Col lg="7" md="7" sm="12" xs="12" className="textPart">
        <h2><span className="titleIcon"><FaProjectDiagram/></span> Design & Visualize</h2>
        <p>Edu Framework provides a wide range of design and visualization functionalities that can effectively help resolve information overload and streamline the creation process. With Edu Framework, users can easily create and design frameworks and structures for organizing and presenting information in a simplified and understandable manner.
 </p>
        </Col>
        <Col lg="5" md="5" sm="12" xs="12">
          <img src={homeImage3} width="80%"/>
        </Col>
        
      </Row>
      
    </div>
    <IconBlock/>
    </Container>
    </>
    
  )
}
