import React, { useEffect } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { FaWalking, FaBiking, FaCarSide, FaRocket, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { usePlan } from '../context/PlanContext';
import './PricingPlans.css';
import PayPalPaymentComponent from './PayPalPaymentComponent';
import PlanPurchase from './PlanPurchase';

export default function PricingPlans() {
  const iconMap = {
    walking: FaWalking,
    biking: FaBiking,
    carSide: FaCarSide,
    rocket: FaRocket,
  };

  const { plans, getPlans } = usePlan();
  const navigate = useNavigate();

  useEffect(() => {
    getPlans();
  }, []); // Fetch plans only when component mounts

  const saveSelectionAndSignin = (plan) => {
    alert('You have selected ' + plan.name + ' plan');
    localStorage.setItem('selectedPlan', JSON.stringify(plan));
   
    navigate('/signin');
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Row>
              {plans?.map((plan, index) => (
                <Col lg={3} sm={12} md={6} key={index}>
                  <div className="planContainer">
                    <h2 className="planTitle">{plan.name}</h2>
                    <div className="planLogo">{iconMap[plan.icon] && React.createElement(iconMap[plan.icon])}</div>

                    {plan.cost === 0 ? (
                      <>
                        <div className="planPrice">Free </div>
                        <p>15 days trial</p>
                      </>
                    ) : (
                      <>
                        <div className="planPrice">${plan?.cost} </div>
                        <p>/month - Billed annually</p>
                      </>
                    )}
                    <div className="planText">
                      <p>
                        {plan?.description}
                        <ul>
                          {plan.features.map((item, index) => (
                            <li key={index}>
                              {item.isAvailable ? <FaCheckCircle /> : <FaTimesCircle />} {item.feature}
                            </li>
                          ))}
                        </ul>
                      </p>
                    </div>
                    <div className="d-grid gap-2">
                      <Button
                        className="planButton"
                        onClick={() => saveSelectionAndSignin(plan)}
                      >
                        Sign Up or Sign In to Purchase
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
