import React from 'react'
import { useFramework } from '../context/FrameworkContext'
import './CompetencyHoverTooltip.css'
import { FaCube } from 'react-icons/fa';
export default function CompetencyHoverTooltip() {
    const {selectedCompetency,tooltipPosition,tooltipIsVisible}=useFramework();
  
  return (
    <div className="toolTipBlock" style={{top:(tooltipPosition.y-180)+"px",left:(tooltipPosition.x+(tooltipPosition.direction==='right'?12:-313))+"px",visibility:tooltipIsVisible}}>
        <div className={(tooltipPosition.direction==='right'?'leftHandle':'rightHandle')}></div>
        <div className="tooltipTitle" style={{backgroundColor:selectedCompetency.color}}><FaCube/>&nbsp;
        {selectedCompetency.name}
          
        </div>

        <div className="tooltipBody">
          <table>
            <tbody>
            <tr>
              <td><b>Description:</b></td>
              <td>{selectedCompetency.description}</td>
            </tr>
            <tr>
              <td><b>Type:</b></td>
              <td>{selectedCompetency.type}</td>
            </tr>
            <tr>
            <td><b>Level:</b></td>
              <td>{selectedCompetency.level}</td>
            </tr>
            <tr>
            <td><b>Status:</b></td>
              <td>{selectedCompetency.status}</td>
            </tr>
            <tr>
            <td colSpan="2"><b>Requirement Of</b></td>
            </tr>
            {selectedCompetency.requirementOf?.map((rf,index)=>{
              return <tr>
                <td id={'n'+index}>{rf.certificateName}</td>
                <td id={'l'+index}>{rf.certificateLevel}</td>
              </tr>
              
               })}
               </tbody>
          </table>
        </div>      
    </div>
  )
}
