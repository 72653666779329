import React from 'react'
import {Form,Row,Col } from 'react-bootstrap';
import { useFramework } from '../context/FrameworkContext';
import './ColorCodeSelector.css'
export default function ColorCodeSelector() {
  const {setColoredBy}=useFramework();
  const handleColorByChange=(event)=>{
    setColoredBy(event.target.value)
  }
  return (
<>
  <Row className="colorCodeSelector"> 
    <Col lg="4" md="4" sm="4" sx="6" className="colorCodeSelectorLabel"><b>Color Code</b></Col>
    <Col>
    <Form.Select className="colorCodeSelect"
                onChange={handleColorByChange}
    >
        <option value="type">Competency Type</option>
        <option value="level">Competency Level</option>
        <option value="status">Competency Status</option>
        
    </Form.Select>
    </Col>
  </Row>
</>
)
}