import React from 'react';
import { FaWpforms, FaHome, FaCoffee, FaCertificate } from 'react-icons/fa'; // Import only the required icons
  
export default function IconComponent({ iconName }) {
    const Icon = getIconByName(iconName); // Get the icon based on the name
  
    return (
      <div>
        {Icon ? <Icon /> : <p>Icon not found</p>} {/* Render the icon or fallback */}
      </div>
    );
  }
  // Define a function that returns the correct icon based on the name
  const getIconByName = (iconName) => {
    const iconMap = {
      Frameworks: FaWpforms,
      Courses: FaHome,
      Programs: FaCoffee,
      Certificates:FaCertificate
    };
  
    return iconMap[iconName] || null; // Return the icon component or null if not found
  };
  