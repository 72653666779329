import React from 'react'
import {useCertificate} from '../context/CertificateContext';
import {Modal,Button} from 'react-bootstrap'
import Message from './Message'
import CertificateEditor from './CertificateEditor';

export default function CertificateEditorModal() {
const {setEditModalIsVisible,editModalIsVisible,setMessage,message,selectedCertificateId,actionType,updateCertificate,saveCertificate}=useCertificate();
  const hideMessageAndContinueEditing=()=>{
    setMessage({target:"certificateModal",header:"",text:"",variant:"",buttons:[],isVisible:false})
  }
  const hideMessageAndHideModal=()=>{
    setMessage({target:"certificateModal",header:"",text:"",variant:"",buttons:[],isVisible:false})
    setEditModalIsVisible(false);
    
  }

  const handleSaveCertificate=()=>{
    console.log(selectedCertificateId)

    if (actionType==="edit"){
 
      updateCertificate(selectedCertificateId)
    }
    if (actionType==="add"){
      saveCertificate(selectedCertificateId)
    }
    
    setEditModalIsVisible(false);
    
  }
  
  const handleClose=()=>{
    setMessage({target:"certificateModal",header:"Are You Sure",
                text:"You are canceling without saving, note that all unsaved information will be be lost ",
                variant:"warning",
                buttons:[{text:"Yes",action:hideMessageAndHideModal},
                         {text:"No",action:hideMessageAndContinueEditing}],
                isVisible:true})
      
  }



    return (
        <>
        <Modal show={editModalIsVisible} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Certificate</Modal.Title>
          </Modal.Header>
    
          <Modal.Body>
            {message.target==="certificateModal"?
            <Message
             
              header={message.header}
              text={message.text}
              buttons={message.buttons}
              show={message.isVisible}
              variant={message.variant}
              position="fixed"
            ></Message>:null}
            <CertificateEditor/>
          </Modal.Body>
    
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
            <Button variant="primary" onClick={handleSaveCertificate}>Save changes</Button>
          </Modal.Footer>
        </Modal>
        </>
      )
}
