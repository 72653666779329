import React from 'react'
import {FaUser, FaSignOutAlt, FaUserCog,FaHistory} from 'react-icons/fa'
import { NavDropdown } from 'react-bootstrap'
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
export default function LoginDropDown() {
    let icon=<div className="loginIcon"><FaUser/></div>
    const {logout, user} = useAuth();
    const navigate = useNavigate();
    const handleLogout = () => {  
        logout()
    }
    const handleChangePassword = () => {
        navigate('/change-password')
    }
    const handleManageProfileClick = () => {
        navigate('/manage-profile')
    }
    const handleShowHistory = () => {
        navigate('/history')
    }
    return (
    <>
    <NavDropdown title={icon} id="basic-nav-dropdown">
            <NavDropdown.Item disabled style={{backgroundColor:'black',color:'white'}}>Hello {user.fullName}</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleManageProfileClick}><FaUserCog /> Manage Profile</NavDropdown.Item><NavDropdown.Divider />
            <NavDropdown.Item onClick={handleShowHistory} ><FaHistory /> Purchase History</NavDropdown.Item>
            {!(user.googleId || user.facebookId) &&   <NavDropdown.Item onClick={handleChangePassword} >Change Password</NavDropdown.Item>}
            <NavDropdown.Divider />
            <NavDropdown.Item   onClick={handleLogout}>
            <FaSignOutAlt /> Logout
            </NavDropdown.Item>
    </NavDropdown>
    </>
  )
}
