import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './CertificateView.css';
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';

export default function CertificateView(props) {
  const certificateRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => certificateRef.current,
    documentTitle: props.data.title || 'Certificate Report',
  });

  return (
    <div  ref={certificateRef}>
      <Row>
        <Col lg="12" md="12" sm="12" sx="12" className="certificateInformation">
          Certificate Information    <button title="Print" onClick={handlePrint} className="btn btn-primary printButton" style={{ marginTop: '20px' }}><FaPrint /></button>
        </Col>
      </Row>

      {props.data.title !== undefined ? (
        <div className="viewContainer">
          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Certificate Title: </Col>
            <Col className="viewValue">{props.data.title}</Col>
          </Row>

          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Issued By: </Col>
            <Col className="viewValue">{props.data.issuedBy}</Col>
          </Row>

          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Certificate For: </Col>
            <Col className="viewValue">
              {props.data.certificateFor === 'Course' && (
                <Link to={`/courses/${props.data.courseID}`}>{props.data.certificateFor}</Link>
              )}
              {props.data.certificateFor === 'Program' && (
                <Link to={`/programs/${props.data.programID}`}>{props.data.certificateFor}</Link>
              )}
              {props.data.certificateFor === 'Framework' && (
                <Link to={`/frameworks/${props.data.FrameworkID}`}>{props.data.certificateFor}</Link>
              )}
              {props.data.certificateFor === 'Level' && (
                <Link to={`/levels/${props.data.levelID}`}>{props.data.certificateFor}</Link>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Achievement: </Col>
            <Col className="viewValue">{props.data.acheivementDescription}</Col>
          </Row>

          <Row>
            <Col lg="3" md="3" sm="5" sx="12" className="viewLabel">Validity Duration: </Col>
            <Col className="viewValue">{props.data.validityDuration}</Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row>
            <Col>Please select a certificate</Col>
          </Row>
        </div>
      )}

    
    </div>
  );
}
