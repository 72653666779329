import React,{useState,useEffect} from 'react'
import {Row,Col} from 'react-bootstrap'
import { FaPencilAlt,FaTrashAlt,FaRegCheckSquare,FaRegSquare, FaFilter } from 'react-icons/fa'
import Message from './Message'
import './GenericList.css'
import FilterSelector from './FilterSelector'
export default function GenericList(props) {
const [filteredData,setFilteredData]=useState(props.data)
useEffect(() => {
    setFilteredData(props.data);
}, [props.data]);

const handleFiltering=(listFilters)=>{
    console.log(listFilters)
let newData=props.data
if (listFilters.length>0) {
    listFilters.forEach(filter=>{
        console.log(filter.columnDBName)
   console.log(newData[0][filter.value])     
    newData=newData.filter(item=>item[filter.columnDBName]?.toString().toLowerCase().includes(filter.value.toString().toLowerCase()))
})}
console.log(newData)
setFilteredData(newData)

}

  return (
    <>
    {props.listMessage.target===props.listHeader?
    <Message
    
    header={props.listMessage.header}
    text={props.listMessage.text}
    buttons={props.listMessage.buttons}
    show={props.listMessage.isVisible}
    variant={props.listMessage.variant}
    position="relative"
    ></Message>:null}
    <Row className="listHeader">
                <Col  >
                    {props.listHeader} ({filteredData.length})
                    <button title="Add Item" className="addDataItemButton" onClick={props.handleAdd}>+</button>
                    <FilterSelector 
                    columnsNames={props.columnsNames} 
                    columnsHeaders={props.columnsHeaders} 
                    getFilters={handleFiltering}/> 
                </Col>

    </Row>
    <Row className="listContainer">
        <Col>
            
           
            <Row>
                <Col className="listItems" >

                        {filteredData?.map((dataItem,index)=>{
                            
                            return(
                            <Row key={index} className="listItem" style={(props.selectedItemId===dataItem._id)?{backgroundColor:'#699ada',color:'white',fontWeight:'bolder'}:{}}>
                                <Col lg="2" md="2" sm="3" sx="3">
                                        <button title="select" selectid={dataItem._id} key={"select"+index} className="appButtonIcons " onClick={(event)=>props.handleSelect(event)}>{(props.selectedItemId===dataItem._id)?<FaRegCheckSquare/>:<FaRegSquare />}</button>
                                </Col>
                                <Col className="dataSpan">
                                    <Row >
                                        
                                        {props.columnsNames.map((columnName,index1)=>{
                                            
                                        return(  <Col lg="12" md="12" sm="12" sx="12" key={index1} className="singleDataColumn"><b>{props.columnsHeaders[index1]}</b>:{dataItem[columnName]}</Col>)
                                        })}
                                    </Row>
                                </Col>
                                <Col className="actionSpan" lg="3" md="3" sm="4" sx="4">
                                <button title="edit" editid={dataItem._id} key={"edit"+index} className="appButtonIcons " onClick={(event)=>props.handleEdit(event)}><FaPencilAlt /></button>
                                <button title="delete" deleteid={dataItem._id} key={"delete"+index} className="appButtonIcons " onClick={(event)=>props.handleDelete(event)}><FaTrashAlt /></button>
                                </Col>
                            </Row>
                            )
                        })}
                    <div>

                    </div>
                </Col>
            </Row>
        </Col>
    </Row>
    </>
  )
}
