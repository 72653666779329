import React from 'react'
import FrameworkEditor from './FrameworkEditor'
import {Modal,Button} from 'react-bootstrap'
import { useFramework } from '../context/FrameworkContext'
import Message from './Message'
export default function FrameworkEditorModal() {
    
    const {editModalIsVisible,setEditModalIsVisible,toggleEditModal,message,setMessage,getFrameworks,updateFramework,selectedFrameworkID}=useFramework()
    const hideMessageAndContinueEditing=()=>{
      setMessage({header:"",text:"",variant:"",buttons:[],isVisible:false})
    }
    const hideMessageAndHideModal=()=>{
      setMessage({header:"",text:"",variant:"",buttons:[],isVisible:false})
      setEditModalIsVisible(false);
      getFrameworks()
    }

    const handleSaveFramework=()=>{
      console.log(selectedFrameworkID)
      updateFramework(selectedFrameworkID)
      
      setEditModalIsVisible(false);
      
    }
    
    const handleClose=()=>{
      setMessage({header:"Are You Sure",
                  text:"You are canceling without saving, note that all unsaved information will be be lost ",
                  variant:"warning",
                  buttons:[{text:"Yes",action:hideMessageAndHideModal},
                           {text:"No",action:hideMessageAndContinueEditing}],
                  isVisible:true})
        
    }
 
  return (
    <>
    <Modal show={editModalIsVisible} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Edit Framework</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Message 
          header={message.header}
          text={message.text}
          buttons={message.buttons}
          show={message.isVisible}
          variant={message.variant}
          position="fixed"
        ></Message>
        <FrameworkEditor/>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <Button variant="primary" onClick={handleSaveFramework}>Save changes</Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
