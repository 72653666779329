import React from 'react'
import HelpCategoriesList from '../components/HelpCategoriesList'
import { Container } from 'react-bootstrap'
import HelpCategoryContextprovider from '../context/HelpCategoriesContext'
import { FaBook } from 'react-icons/fa'
export default function LearningResourcesPage() {
  return (
  <>
  <Container>
    <p className="appPageIcon"><FaBook/></p>
    <h2 className="appPageTitle">| Learning Resources</h2>
    <HelpCategoryContextprovider>
      <HelpCategoriesList categoryName="Educational Resources"/>
    </HelpCategoryContextprovider>
    </Container>
  </>
  )
}
