import React,{useEffect} from 'react'
import { Container} from 'react-bootstrap'
import CoursesList from '../components/CoursesList';
import CourseContextprovider from '../context/CourseContext';
import CourseEditorModal from '../components/CourseEditorModal';
import SettingContextprovider from '../context/SettingContext';
import {FaChalkboardTeacher} from 'react-icons/fa'

export default function CoursesManagementPage() {
  
  return (
   <>
 
   <CourseContextprovider>
   <SettingContextprovider>
        <CourseEditorModal/>
        <p className="appPageIcon"><FaChalkboardTeacher/> </p>
        
        <h2 className="appPageTitle"> | Courses Management</h2>
        <CoursesList/>
    </SettingContextprovider>
  </CourseContextprovider>
   



   </>
  )
}
