import React, { useEffect, useState } from 'react';
import CollapsableItem from './CollapsableItem';
import { Form, Row, Col } from 'react-bootstrap';
import { useHelpCategory } from '../context/HelpCategoriesContext';
import './HelpCategoriesList.css';

export default function HelpCategoriesList(props) {
  const { getHelpCategories, userHelpCategories } = useHelpCategory();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getHelpCategories(props.categoryName);
  }, [props.categoryName, getHelpCategories]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Row className="helpCategoryTitle justify-content-md-center">
        <Col lg={10}>
          <Form.Control
            type="text"
            name="search"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search help topics..."
            className="mb-4"
          />
        </Col>
      </Row>

      {userHelpCategories?.map((category, index) => (
        <React.Fragment key={index}>
          <Row>
            <Col>
              {category.subjects
                ?.filter(subject => {
                  // Filter based on subject title
                  return subject?.title?.toLowerCase().includes(searchQuery.toLowerCase());
                })
                .map((subject, idx) => (
                  <CollapsableItem key={idx} subject={subject} />
                ))
              }
            </Col>
          </Row>
        </React.Fragment>
      ))}
    </>
  );
}
