import React, { useRef, useEffect } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import { FaPrint } from 'react-icons/fa';
import { useHistory } from '../context/HistoryContext';

export default function PurchaseHistory() {
  const historyRef = useRef();
  const { userHistories, getUserHistories } = useHistory();
  
  const handlePrint = useReactToPrint({
    content: () => historyRef.current,
    documentTitle: 'Purchase History',
  });

  useEffect(() => {
    getUserHistories();
  }, [getUserHistories]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? 'Invalid Date' : date.toLocaleDateString();
  };

  return (
    <div>
      <Row className="align-items-center mb-4">
        <Col>
          <h2>Purchase History</h2>
        </Col>
        <Col className="text-end">
          <Button onClick={handlePrint} variant="primary">
            <FaPrint /> Print History
          </Button>
        </Col>
      </Row>

      <div ref={historyRef}>
        {userHistories.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Plan Name</th>
                <th>Cost</th>
                <th>Payment Method</th>
                <th>Subscription Start Date</th>
                <th>Subscription End Date</th>
              </tr>
            </thead>
            <tbody>
              {userHistories.map((history, index) => (
                <tr key={history._id}>
                  <td>{index + 1}</td>
                  <td>{history.planId.name}</td>
                  <td>${history.cost}</td>
                  <td>{history.paymentMethod}</td>
                  <td>{formatDate(history.subscriptionStartDate)}</td>
                  <td>{formatDate(history.subscriptionEndDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No purchase history found.</p>
        )}
      </div>
    </div>
  );
}
