import React,{useEffect,useState} from 'react'

import {Row,Col} from 'react-bootstrap'
import {  FaPencilAlt,FaRegHandPointRight, FaTrashAlt } from 'react-icons/fa'
import { useFramework } from '../context/FrameworkContext'
import './FrameworksList.css'
import Message from './Message'
export default function FrameworksList(props) {
  const [listMessage,setListMessage]=useState({header:"",text:"",variant:"",buttons:[],isVisible:false})
  const {userFrameworks,selectedFrameworkID,setSelectedFrameworkID,setFramework,addEmptyFramework,getFrameworks,setMessage,setEditModalIsVisible,removeFramework}=useFramework();
  useEffect(()=>{
    if (props.selectedFramwworkID!==undefined) setSelectedFrameworkID(props.selectedFramwworkID)
    getFrameworks();  

},[])
  
const handleAddFrameworkButton=()=>{
  let id=addEmptyFramework();
  setSelectedFrameworkID(id)
  let theSelectedFrameworkIndex=userFrameworks.findIndex(theFramework=>theFramework._id===id);
    if (theSelectedFrameworkIndex>-1){
      setFramework(userFrameworks[theSelectedFrameworkIndex])
    }
  

}  
  const handleSelectAFramework=(event)=>{
    let isSuccessfull=false;
    let selectedItemID=event.target.getAttribute("itemid")
    if(selectedItemID!==null){
      setSelectedFrameworkID(selectedItemID)
      let theSelectedFrameworkIndex=userFrameworks.findIndex(theFramework=>theFramework._id===selectedItemID);
      if (theSelectedFrameworkIndex>-1){
        setFramework(userFrameworks[theSelectedFrameworkIndex])
        isSuccessfull=true;
      }
    }
    console.log(event.target)
    return isSuccessfull;
  }
  const handleEditAFramework=(event)=>{
    if(handleSelectAFramework(event)) setEditModalIsVisible(true);
    
  }
  const handleCompleteDelete=()=>{
    removeFramework(selectedFrameworkID)
    setListMessage(({header:"",text:"",variant:"",buttons:[],isVisible:false}))
    
  }
  const handleCancel=()=>{
    setListMessage(({header:"",text:"",variant:"",buttons:[],isVisible:false}))
  }
  const handleDeleteAFramework=(event)=>{
    if(handleSelectAFramework(event)) setListMessage({header:"Delete a Framework",text:"Are you sure that you want to delete this framework and all related roles? kindly note that this is permenant ",variant:"warning",buttons:[{text:"Yes, I am sure",action:handleCompleteDelete},{text:"Cancel",action:handleCancel}],isVisible:true})
  }

  return (
    <>
     <Row className="frameworksListHeaders">
        <Col >
        Frameworks ({userFrameworks.length})
       
      <button className="addFrameworkButton" onClick={handleAddFrameworkButton}>+</button>
      
        </Col>
        <Message 
          header={listMessage.header}
          text={listMessage.text}
          buttons={listMessage.buttons}
          show={listMessage.isVisible}
          variant={listMessage.variant}
          position="relative"
       ></Message>
    </Row>
    <Row className="frameworksListContainer">
      <Col lg="12" md="12" sx="12" sm="12">
     
     
    {userFrameworks.map((oneFramework,index)=>{
      return(
       
      <Row key={index} className={"frameworksListItem "+(oneFramework._id===selectedFrameworkID?'frameworkSelected':'')}>
        
        <Col sm="1" sx="3" md="2" lg="1">
           <input type="radio" name="selectedFrameworkID" value={oneFramework._id} className="frameworkSelector" itemid={oneFramework._id} checked={selectedFrameworkID===oneFramework._id} onClick={handleSelectAFramework} onChange={handleSelectAFramework}/>
          
        </Col>
        <Col sm="8" sx="4" md="8" lg="9" >
          <label ><b> &nbsp;{oneFramework.frameworkName}</b>: {oneFramework.frameworkDescription.length>100?oneFramework.frameworkDescription.substring(0,100)+"...":oneFramework.frameworkDescription}  </label>
        </Col>
        <Col sm="3" sx="5" md="2" lg="2">
          <button itemid={oneFramework._id} className="appButtonIcons " onClick={handleEditAFramework}><FaPencilAlt /></button>
          <button itemid={oneFramework._id} className="appButtonIcons " onClick={handleDeleteAFramework}><FaTrashAlt /></button>
        </Col>
       
      </Row>      
        
     
      )
    }
      

    )}

     

    </Col>
   </Row>

    </>
  )
}
