import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './ReportsView.css';
import config from '../api/config';
import { useSettings } from '../context/SettingContext';

import IconComponent from './IconComponent';


export default function ReportsView() {
  const { getSettingByName, settingData } = useSettings() || {};
  const [groupedReports, setGroupedReports] = useState({});

  useEffect(() => {
    const fetchAndGroupReports = async () => {
      if (getSettingByName) {
        await getSettingByName("reports");  // Fetch the reports data
      }
    };

    fetchAndGroupReports();
  }, []);  // Only run when getSettingByName changes

  useEffect(() => {
    if (settingData?.reports) {
      // Group reports by category once settingData is updated
      const grouped = settingData.reports.reduce((acc, report) => {
        const { reportCategory } = report;
        if (!acc[reportCategory]) {
          acc[reportCategory] = [];
        }
        acc[reportCategory].push(report);
        return acc;
      }, {});

      setGroupedReports(grouped || {});
    }
  }, [settingData?.reports]);  // Only group reports when settingData.reports changes

  return (
    <div>
      <Row>
        {Object.keys(groupedReports).map((category, categoryIndex) => (
          <Col key={categoryIndex} lg={6} md={6} sm={12} sx={12}>
            <h3 className="reportCategory"><IconComponent iconName={category} />{category}</h3>
            <Row>
              {groupedReports[category].map((report, index) => (
                <Col key={index} lg={12} md={12} sm={12} sx={12}>
                  <a className="btn reportButtons" href={config.clientBasedURL + report.reportLink}>
                    {report.reportName}
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}
