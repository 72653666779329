import React, { useEffect } from 'react';
import { useProgram } from '../context/ProgramContext'; // Assume context for programs exists
import { Table } from 'react-bootstrap'; // Use Bootstrap Table for formatting

const AllProgramsReport = () => {
  const { programs, getPrograms } = useProgram();

  // Fetch all programs when the component is mounted
  useEffect(() => {
    getPrograms(); // Function to fetch all programs
  }, [getPrograms]);

  return (
    <div className="all-programs-report">
      <h2>All Programs Report</h2>
      {programs?.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Program Name</th>
              <th>Description</th>
              <th>Level</th>
              <th>Audience</th>
              <th>Courses</th>
              <th>Related Frameworks</th>
            </tr>
          </thead>
          <tbody>
            {programs.map((program, index) => (
              <tr key={program._id}>
                <td>{index + 1}</td>
                <td>{program.name}</td>
                <td>{program.description}</td>
                <td>{program.level}</td>
                <td>{program.audience?.who}</td>
                <td>{program.courses?.length}</td>
                <td>{program.relatedFrameworks?.length}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No programs available.</p>
      )}
    </div>
  );
};

export default AllProgramsReport;
