import React,{useState} from 'react'
import {Alert,Button} from 'react-bootstrap'


export default function Message(props) {
  
 
  return (
    <>
    
    <Alert className="appMessage" show={props.show} variant={props.variant} style={{position:props.position}}>
        <Alert.Heading>{props.header}</Alert.Heading>
        <p>{props.text}</p>
        <div className="d-flex justify-content-end">
            {props.buttons.map((button,index)=>{
            return(
            <>
                &nbsp;<Button key={index}  name={button.text} onClick={event=>button.action()} variant={props.variant}>
                {button.text}
                </Button>
            </>
            )
            })}
       </div>
        
    </Alert>
    
     
    </>
  )
}
